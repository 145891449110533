import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Page {
  constructor() {
    let scrollPos;
    let cache = {};
    let timer;
    let initFunc = () => {
      initCache();
      registerEvents();
      loader();
    }

    let initCache = () => {
      cache.loader = document.querySelector('.loader');
      cache.loaderFrame = document.querySelector('.loader .loader_frame');
      cache.loaderDog = document.querySelector('.loader .loader_dog_body');
      cache.loaderHat = document.querySelector('.loader .loader_dog_hat');
      cache.page = document.querySelector('.wrapper');

      cache.dog = document.querySelector('.dog');
      cache.dogWoff1 = document.querySelector('.dog .woof1');
      cache.dogWoff2 = document.querySelector('.dog .woof2');
    };

    let registerEvents = () => {
      cache.dog.addEventListener('click', woof);
    };

    let loader = function() {
      anime({
        targets: cache.loaderHat,
        translateY: [0,-140],
        rotate: -10,
        duration: 200,
        delay: 1000,
        easing: 'easeOutQuad',
        complete: function() {
          anime({
            targets: cache.loaderHat,
            translateY: 600,
            rotate: 320,
            duration: 800,
            delay: 800,
            easing: 'easeInQuad',
          });
        }
      });
      anime({
        targets: cache.loaderDog,
        translateY: ['0%','200%'],
        duration: 600,
        delay: 1500,
        easing: 'easeInQuad',
      });
      anime({
        targets: cache.loaderFrame,
        scale: 0,
        duration: 800,
        delay: 2600,
        easing: 'easeInQuad',
        complete: function() {
          cache.loader.style.display = "none";
        }
      });
      if(globalVar.desktop.matches) {
        anime({
          targets: cache.page,
          scale: [10, 1],
          duration: 1200,
          delay: 2800,
          easing: 'easeOutQuad',
          complete: function() {
            document.querySelector('.content').style.height = "auto";
          }
        });
        anime({
          targets: cache.page.querySelector('.left'),
          opacity: [0, 1],
          duration: 1000,
          delay: 3400,
          easing: 'easeOutQuad',
        });
      } else {
        anime({
          targets: cache.page,
          scale: [10, 1],
          translateX: ['-15%','0%'],
          duration: 1200,
          delay: 2600,
          easing: 'easeOutQuad',
          complete: function() {
            document.querySelector('.content').style.height = "auto";
          }
        });
        anime({
          targets: cache.page.querySelector('.left'),
          opacity: [0, 1],
          duration: 1000,
          delay: 3400,
          easing: 'easeOutQuad',
        });
      }
    }

    let woof = function() {
      if(timer) {
        clearTimeout(timer);
      }
      cache.dogWoff1.style.display = "block";
      anime({
        targets: cache.dogWoff1,
        opacity:[0,1],
        scale: [0,1],
        duration: 600,
        easing: 'easeInExpo',
      });
      cache.dogWoff2.style.display = "block";
      anime({
        targets: cache.dogWoff2,
        opacity:[0,1],
        scale: [0,1],
        duration: 600,
        delay: 200,
        easing: 'easeInExpo',
      });

      timer = setTimeout(clearWoof, 1000);
    }

    let clearWoof = function() {
        anime({
          targets: cache.dogWoff1,
          opacity:[1,0],
          scale: [1, 1.5],
          duration: 600,
          easing: 'easeInExpo',
        });
        anime({
          targets: cache.dogWoff2,
          opacity:[1,0],
          scale: [1, 1.5],
          duration: 600,
          delay: 200,
          easing: 'easeInExpo',
        });
    }

    initFunc();
  };
}
export { Page }
