import { Page } from './pages/page'
import { Dropdown } from './pages/dropdown'


class App {
  constructor() {

    const page = new Page;
    
    const dropdown = new Dropdown();
    
  }
}

window.onload = function() {
  let app = new App();
};
