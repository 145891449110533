import utils from '../utils/utils'
import anime from '../libs/anime'
import globalVar from '../utils/globalVar'

class Dropdown {
  constructor() {
    let scrollPos;
    let cache = {};
    let self = this;
    let scrollElement = window.document.scrollingElement || window.document.body || window.document.documentElement;
    let desktop = window.matchMedia("(min-width: 1024px)");
    let init = () => {
      initCache();
      registerEvents();
    }

    let initCache = () => {
      cache.btn = document.querySelector(".form_wrap .btn");
      cache.form = document.querySelector(".form_wrap .form_container");
    };

    let registerEvents = () => {
      cache.btn.addEventListener('click', toggleDrop);
    };

    let toggleDrop = function() {
      anime({
        targets: cache.btn,
        translateY: 100,
        opacity: 0,
        duration: 300,
        easing: 'easeInOutBounce',
        complete: function() {
          cache.form.style.display = "block";
          cache.form.style.height = 'auto';
          cache.form.style.opacity = 0;
          var height = cache.form.offsetHeight;
          cache.form.style.height = 0;
          cache.form.style.opacity = 1;
          anime({
            targets: cache.form,
            height: height,
            duration: 800,
            easing: 'easeInOutExpo',
            complete: function() {
              cache.form.querySelector('._field-wrapper input').focus();
            }
          });
          anime({
            targets: cache.btn,
            height: 0,
            padding: 0,
            duration: 800,
            easing: 'easeInOutExpo',
          });
        }
      });
    }

    init();
  };
}
export { Dropdown }
